@font-face {
	font-family: 'DIN Pro';
	src: url('DINPro-Medium.eot');
	src: local('DIN Pro Medium'), local('DINPro-Medium'),
		url('DINPro-Medium.eot?#iefix') format('embedded-opentype'),
		url('DINPro-Medium.woff') format('woff'),
		url('DINPro-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'DIN Pro';
	src: url('DINPro.eot');
	src: local('DIN Pro'), local('DINPro'),
	url('DINPro.eot?#iefix') format('embedded-opentype'),
	url('DINPro.woff') format('woff'),
	url('DINPro.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Fedra Sans Pro Book';
	src: url('FedraSansPro-Book.woff2') format('woff2'),
	url('FedraSansPro-Book.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Fedra Sans Alt Pro Book';
	src: url('FedraSansAltPro-Book.woff2') format('woff2'),
	url('FedraSansAltPro-Book.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Fedra Sans Pro';
	src: url('FedraSansPro-Light.woff2') format('woff2'),
	url('FedraSansPro-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Fedra Sans Pro';
	src: url('FedraSansPro-Normal.woff2') format('woff2'),
	url('FedraSansPro-Normal.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Fedra Sans Alt Pro';
	src: url('FedraSansAltPro-Medium.woff2') format('woff2'),
	url('FedraSansAltPro-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Fedra Sans Alt Pro';
	src: url('FedraSansAltPro-Normal.woff2') format('woff2'),
	url('FedraSansAltPro-Normal.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Fedra Sans Pro';
	src: url('FedraSansPro-Medium.woff2') format('woff2'),
	url('FedraSansPro-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Fedra Sans Alt Pro';
	src: url('FedraSansAltPro-Light.woff2') format('woff2'),
	url('FedraSansAltPro-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

