@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

* {
  margin: 0;
  padding: 0;
  outline: none !important;
  box-sizing: border-box;
  text-decoration: none !important;
}

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  color: #46494a;
  font-family: 'Fedra Sans Pro Book';
  line-height: normal;
}

body {
  background: #ffffff;
}

html {
  font-size: 10px;
}

.justify-wrap {
  text-align: justify;
  font-size: 0;
  text-justify: newspaper;
}

.justify-wrap:after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: 0;
}

[placeholder]:focus::-webkit-input-placeholder {
  color: transparent !important;
}

[placeholder]:focus:-moz-placeholder {
  color: transparent !important;
}

[placeholder]:focus::-moz-placeholder {
  color: transparent !important;
}

[placeholder]:focus:-ms-input-placeholder {
  color: transparent !important;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: ' ';
}

.clearfix:after {
  clear: both;
}

.container {
  margin: 0 auto;
  box-sizing: border-box;
  min-width: 320px;
  max-width: 128rem;
  width: 100%;
  padding-left: 5.5rem;
  padding-right: 5.5rem;

  @media screen and (max-width: 1023px) {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}

.wrapper {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  min-width: 320px;
  min-height: 100%;
}

.content {
  .container {
    padding-top: 31.5rem;
    padding-bottom: 14.8rem;

    @media screen and (max-width: 1023px) {
      padding-top: 25.6rem;
      padding-bottom: 3.2rem;
    }
  }
}

.sc__promo {
  margin-bottom: 4.7rem;

  @media screen and (max-width: 1023px) {
    margin-bottom: 2.4rem;
  }

  &-title {
    font-size: 9.4rem;
    line-height: 11.8rem;
    margin-bottom: 6.4rem;
    font-weight: 350;
    font-family: 'Fedra Sans Pro';

    @media screen and (max-width: 1023px) {
      font-size: 4.4rem;
      line-height: 6rem;
      margin-bottom: 5rem;
      font-weight: 400;
    }

    span {
      position: relative;
      background-color: #fff;
      display: inline-block;

      @media screen and (max-width: 1023px) {
        background: transparent;
      }
    }
  }

  &-decor {
    width: 8.7rem;
    height: 8.7rem;
    background-color: #ff1eff;
    position: relative;
    transform-origin: left bottom !important;

    @media screen and (max-width: 1023px) {
      width: 6.4rem;
      height: 6.4rem;
    }

    &:after {
      content: '';
      top: 1.9rem;
      right: 1.7rem;
      width: 2.8rem;
      height: 2.8rem;
      border-right: 0.4rem solid #fff;
      border-top: 0.4rem solid #fff;
      position: absolute;

      @media screen and (max-width: 1023px) {
        top: 1.2rem;
        right: 1.2rem;
        width: 2.6rem;
        height: 2.6rem;
        border-right: 0.3rem solid #fff;
        border-top: 0.3rem solid #fff;
      }
    }
  }

  &-subtitle {
    font-size: 1.4rem;
    line-height: 2.4rem;
    text-align: right;

    @media screen and (max-width: 1023px) {
      margin-bottom: 2.4rem;
    }

    span {
      display: block;
    }
  }

  &-bottom {
    @media screen and (max-width: 1023px) {
      flex-wrap: wrap;
      flex-direction: column-reverse !important;
      margin-bottom: 3.2rem;
    }
  }
}

.subtitle-line {
  height: 0.5rem;
  left: 0;
  width: 100%;
  top: 100% !important;
  margin-top: -0.15rem;
  background: #626262 !important;
  position: absolute !important;

  @media screen and (max-width: 1023px) {
    height: 0.3rem;
    margin-top: 0.9rem;
  }
}

#pr-span-decor-2 {
  height: 0.5rem;
  left: 0;
  width: 23rem;
  top: 100%;
  margin-top: 1.2rem;
  background: #626262;
  position: absolute;

  @media screen and (max-width: 1023px) {
    height: 0.8rem;
    margin-top: -0.2rem;
  }
}

#pr-span-1 {
  display: block;
}

#pr-span-decor-3 {
  height: 1px;
  left: 26rem;
  right: 1rem;
  top: 100%;
  margin-top: 1.4rem;
  background: #626262;
  position: absolute;

  &:after {
    content: '';
    width: 2.4rem;
    height: 2.4rem;
    transform: rotate(45deg) translateY(-50%);
    margin-top: -0.4rem;
    right: 1.3rem;
    top: 50%;
    border-top: 1px solid #626262;
    border-right: 1px solid #626262;
    position: absolute;

    @media screen and (max-width: 1023px) {
      display: none !important;
    }
  }

  @media screen and (max-width: 1023px) {
    width: 17.7rem;
    height: 2.6rem;
    background: url('../img/pr-arrow.svg') no-repeat center;
    background-size: contain;
    margin-top: -0.2rem;
  }
}

#pr-span-decor-4 {
  height: 0.5rem;
  left: 0;
  width: 100%;
  top: 100%;
  margin-top: 1.2rem;
  background: #626262;
  position: absolute;

  @media screen and (max-width: 1023px) {
    height: 0.3rem;
    margin-top: 0.9rem;
  }
}

.scroll__down {
  height: 10rem;
  cursor: pointer;

  @media screen and (max-width: 1023px) {
    height: auto;
    flex-wrap: wrap;
  }

  &-arrow {
    height: 100%;
    position: relative;
    width: 1px;
    background: #cccccc;
    margin-right: 5.4rem;

    @media screen and (max-width: 1023px) {
      margin-bottom: 0.8rem;
      margin-right: 0;
      background: url('../img/arrow-down.svg') no-repeat center;
      background-size: contain;
      width: 1.6rem;
      height: 4.8rem;
    }

    &:after {
      width: 2.3rem;
      height: 2.3rem;
      border-left: 1px solid #cccccc;
      border-bottom: 1px solid #cccccc;
      position: absolute;
      content: '';
      bottom: 0;
      left: 50%;
      transform: rotate(-45deg);
      transform-origin: left bottom;
      margin-left: 0.05rem;

      @media screen and (max-width: 1023px) {
        display: none !important;
      }
    }
  }

  &-title {
    font-family: 'DIN Pro';
    font-weight: 500;
    color: #46494a;
    font-size: 1.4rem;
    line-height: 1;

    @media screen and (max-width: 1023px) {
      width: 100%;
      font-weight: 400;
    }
  }
}

.news {
  &__net {
    position: relative;
    height: 66rem;
    display: flex;
    flex-wrap: wrap;
    margin-right: -2.5rem;
    margin-left: -2.5rem;
    margin-bottom: 2.7rem;
  }

  &__it {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
    margin-bottom: 5rem;

    &-bl {
      color: #46494a !important;
      width: 100%;
    }

    &-title {
      font-size: 2.4rem;
      line-height: 3.6rem;
      margin-bottom: 2.2rem;
      font-weight: 500;
      font-family: 'Fedra Sans Alt Pro';

      @media screen and (max-width: 1023px) {
        margin-bottom: 0.8rem;
      }
    }

    &-descr {
      font-size: 1.4rem;
      font-family: 'Fedra Sans Alt Pro Book';
      line-height: 2.4rem;
      margin-bottom: 2.2rem;

      @media screen and (max-width: 1023px) {
        margin-bottom: 0;
        margin-top: 2.4rem;
      }
    }

    &-arrow {
      width: 6.9rem;
      height: 0.8rem;
      position: relative;
      margin-top: 3.6rem;
      &:before {
        content: '';
        height: 1px;
        background: #ff1eff;
        left: 0;
        top: 50%;
        margin-top: -0.5px;
        width: 100%;
        position: absolute;
      }

      &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 3.5px 0 3.5px 6px;
        border-color: transparent transparent transparent #ff1eff;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }

    &--1 {
      width: 25%;
      display: flex;
      align-items: flex-start;
      height: 30.5rem;
    }

    &--2 {
      width: 25%;
      display: flex;
      align-items: center;
      height: 66rem;
      .news__it-bl {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 100%;
        color: #fff !important;
        padding: 2.4rem 2.6rem;
      }
    }

    &--3 {
      width: 50%;
      display: flex;
      align-items: center;
      height: 30.5rem;
      position: relative;
      .news__it-bl {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 100%;
        padding: 2.4rem 2.6rem;
      }

      .news__it-social {
        position: absolute;
        z-index: 3;
        left: 5rem;
        bottom: 2.5rem;
      }
    }

    &--4 {
      width: 25%;
      display: flex;
      align-items: center;
      height: 30.5rem;
      .news__it-bl {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 100%;
        position: relative;
      }

      .news__it-ct {
        padding: 2rem 2.6rem;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #00ffff;
        width: 100%;
      }

      .news__it-title {
        font-size: 1.6rem;
        margin-bottom: 1.6rem;
      }

      .news__it-arrow {
        width: 6.9rem;
        height: 0.8rem;
        position: relative;
        margin-top: 1.6rem;
        &:before {
          background: #46494a;
        }

        &:after {
          border-color: transparent transparent transparent #46494a;
        }
      }
    }

    &--lt-bt {
      position: absolute;
      left: 0;
      bottom: 0;
      margin-bottom: 0;
    }

    &--rt-ct {
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-bottom: 0;
    }

    &--rt-bt {
      position: absolute;
      right: 0;
      bottom: 0;
      margin-bottom: 0;
    }

    &-likes {
      margin-right: 7.5rem;

      @media screen and (max-width: 1023px) {
        margin-right: 0;
        width: 6rem;
      }

      &-info {
        display: flex;
        align-items: center;
        height: 1.2rem;
        font-size: 1rem;
        line-height: 1.2rem;
        color: #000000;
        font-weight: 500;
        font-family: 'Fedra Sans Alt Pro';

        @media screen and (max-width: 1023px) {
          font-size: 1.2rem;
        }
      }
    }

    &-date {
      font-size: 1rem;
      line-height: 1.2rem;
      color: #46494a;
      font-weight: 500;
      font-family: 'Fedra Sans Alt Pro';
      margin-bottom: 1.2rem;

      @media screen and (max-width: 1023px) {
        margin-bottom: 0.8rem;
        font-weight: 400;
      }
    }

    &-img {
      position: relative;

      img {
        display: block;
        width: 100%;
      }
    }

    &-comments {
      display: flex;
      align-items: center;
      height: 1.2rem;
      font-size: 1rem;
      line-height: 1.2rem;
      color: #46494a;
      font-weight: 500;
      font-family: 'Fedra Sans Alt Pro';

      @media screen and (max-width: 1023px) {
        font-size: 1.2rem;
      }
    }

    &-decor {
      position: absolute;
      width: 7.2rem;
      height: 7.2rem;
      border-right: 3px solid #46494a;
      border-bottom: 3px solid #46494a;
      bottom: 0;
      right: 0;
    }

    &-info {
      margin-top: -0.6rem;
      & + .news__it-arrow {
        margin-top: 1.6rem;
      }
    }
  }
}

.news__it-social {
  position: absolute;
  left: 2.5rem;
  bottom: 0;
  width: 3.8rem;
  height: 3.8rem;
  background-color: #46494a;
  font-size: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-family: 'DIN Pro';
  color: #fff !important;

  @media screen and (max-width: 1023px) {
    width: 3.6rem;
    height: 3.6rem;
    position: relative;
    left: auto;
    font-weight: 400;
    margin-left: 2.4rem;
  }
}

.icon__likes {
  background: url('../img/likes-icon.png') no-repeat center;
  background-size: contain;
  width: 1.8rem;
  min-width: 1.8rem;
  height: 1.6rem;
  margin-right: 0.5rem;

  @media screen and (max-width: 1023px) {
    margin-right: 1.6rem;
  }
}

.load__more-wrap {
  width: 56rem;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0;
}

.btn__custom {
  height: 5rem;
  background: #eeeeee;
  cursor: pointer;
  font-size: 1.4rem;
  line-height: 1;
  font-weight: 500;
  font-family: 'Din Pro';
  color: #959595 !important;
  width: 100%;

  &:hover {
    background-color: #46494a;
    color: #fff !important;
  }
}

.ab {
  &__box {
    height: 57.8rem;
    overflow: hidden;
    padding-top: 7rem;
    position: relative;

    @media screen and (max-width: 1023px) {
      padding-top: 0;
      margin-top: 4.8rem;
      height: 37.8rem;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -2rem;
    margin-right: -2rem;
  }

  &__it {
    padding-right: 2rem;
    padding-left: 2rem;
    position: relative;
    width: 33.33333%;

    @media screen and (max-width: 1023px) {
      width: auto;
    }

    &-title {
      font-weight: 500;
      font-size: 2.4rem;
      font-family: 'Fedra Sans Pro';
      line-height: 140%;
      margin-bottom: 2.4rem;

      @media screen and (max-width: 1023px) {
        width: 100%;
        margin-bottom: 0;
      }
    }

    &-header {
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      /*height: 13.2rem;*/
    }

    &-lines {
      margin-bottom: 2.4rem;

      @media screen and (max-width: 1023px) {
        width: 100%;
        margin-bottom: 1.2rem;
      }
    }

    &-line {
      height: 0.5rem;
      background-color: #46494a;
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }

      @media screen and (max-width: 1023px) {
        background-color: #959595;
      }
    }

    &-descr {
      font-size: 1.4rem;
      line-height: 2.4rem;

      span {
        background-color: #626262;
        color: #fff;
        padding: 0.3rem 0.8rem;

        @media screen and (max-width: 1023px) {
          background-color: #959595;
        }
      }
    }
  }

  &__arrow {
    height: 1px;
    background: #ff1eff;
    position: absolute;
    left: 0;
    right: -13.5rem;
    bottom: 0;
    transform: rotate(-26.16deg);
    transform-origin: left bottom;

    @media screen and (max-width: 1023px) {
      background: url('../img/arrow-diagonal.svg') no-repeat center;
      background-size: contain;
      width: 10.1rem;
      height: 10.7rem;
      transform: none;
      right: auto;
    }

    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0.8rem 0 0.8rem 1.6rem;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      border-color: transparent transparent transparent #ff1eff;

      @media screen and (max-width: 1023px) {
        display: none !important;
      }
    }

    &-box {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
}

.sc__about {
  margin-bottom: 5.9rem;
}

.fp-watermark {
  display: none !important;
}

.sc__activities {
  .container {
    padding-top: 4rem;
  }
}

/*.sc__challenges{
  .section__scroll-box {
    right: 50%;
    margin-right: -58.4rem;
    margin-left: 0;
    left: auto;
  }

  .scroll__down {
    flex-direction: row-reverse !important;
  }

  .scroll__down-arrow {
    margin-right: 0;
    margin-left: 5.4rem;
  }
}*/

.sc__mission {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 66rem;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;

  @media screen and (max-width: 1023px) {
    margin-left: -3.2rem;
    margin-right: -3.2rem;
    height: 60rem;
    padding-top: 7.6rem;
    padding-bottom: 4rem;
    background-image: url('../img/mission-bg-mb.jpg') !important;
    margin-bottom: 3.2rem;
  }

  .sc__header {
    margin-bottom: 13.7rem;
    width: 100%;

    @media screen and (max-width: 1023px) {
      margin-bottom: 0;
      padding-right: 0.6rem;
    }
  }

  .sc__ct-box {
    width: 66rem;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 1023px) {
      width: 37.3rem;
    }
  }

  .sc__ct-box-wrap {
    overflow: hidden;
  }

  .sc__ct {
    background: #fff;
    padding: 5rem;
    width: 66rem;

    @media screen and (max-width: 1023px) {
      padding: 1.6rem 1.6rem 2.4rem 1.6rem;
      width: 37.3rem;
    }
  }

  .sc__promo-decor {
    position: absolute;
    right: 100%;
    bottom: 2rem;
    margin-right: -0.1rem;

    @media screen and (max-width: 1023px) {
      width: 8.7rem;
      height: 8.7rem;
      bottom: 1.5rem;
      margin-right: 0.5rem;

      &:after {
        right: 1.7rem;
        top: auto;
        bottom: 1.9rem;
        border-top: none;
        border-bottom: 3px solid #fff;
        border-right: 3px solid #fff;
      }
    }
  }
}

.sc__txt {
  font-size: 1.4rem;
  line-height: 2.4rem;

  span {
    background-color: #626262;
    color: #fff;
    padding: 0.3rem 0.8rem;
  }
}

.sc__challenges {
  margin-bottom: 5.6rem;
}

.sc__title {
  color: #fff;
  font-size: 4.8rem;
  text-align: center;
  position: relative;
  font-family: 'Fedra Sans Alt Pro';
}

.schema__box {
  width: 76.6rem;
  margin-bottom: 2.5rem;
  position: relative;
}

.schema__img {
  position: relative;

  img {
    display: block;
    width: 100%;
  }
}

.schema__img-over {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sc__activities {
  .scroll__down {
    margin-bottom: 6rem;
  }
}

.activities__list {
  display: flex;
  flex-wrap: wrap;
  margin-left: 30.6rem;
}

.activities {
  &__it {
    width: 25%;
    padding-left: 1rem;
    padding-right: 1rem;

    &-descr {
      font-size: 1.4rem;
      line-height: 1.8rem;
      font-family: 'Fedra Sans Alt Pro';
      font-weight: 350;

      @media screen and (max-width: 1023px) {
        line-height: 2.4rem;
      }
    }

    &-numb {
      width: 3.8rem;
      height: 2.8rem;
      margin-bottom: 3.6rem;
      font-size: 3rem;
      font-weight: 500;
      line-height: 3.6rem;
      display: flex;
      align-items: flex-start;
      font-family: 'Inter';
      position: relative;

      @media screen and (max-width: 1023px) {
        height: auto;
        top: -0.5rem;
        font-family: 'Fedra Sans Alt Pro';
      }

      &:after {
        content: '';
        position: absolute;
        background-color: #00ffff;
        width: 0.3rem;
        height: 2.8rem;
        left: 3.2rem;
        top: 1.1rem;
      }
    }
  }
}

.schema__target {
  position: absolute;
  left: 0;
  top: 11rem;

  &-title {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-family: 'Fedra Sans Alt Pro';
    font-weight: 500;
    padding-left: 0;
    margin-bottom: 4.6rem;
  }

  &-descr {
    width: 23rem;
    padding: 4rem;
    background: #eeeeee;
    height: 18.4rem;
    font-size: 1.4rem;
    line-height: 2.4rem;
    font-weight: 350;
    position: relative;
    font-family: 'Fedra Sans Alt Pro';

    &:before {
      content: '';
      position: absolute;
      left: 3.5rem;
      bottom: 100%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 3rem 0 0 3rem;
      border-color: transparent transparent transparent #eeeeee;
    }
  }
}

.schema__it {
  position: absolute;

  &-pin {
    width: 2rem;
    height: 2rem;
    border: 0.5rem solid #626262;
    margin-top: 1.8rem;
    border-radius: 50%;
  }

  &-title {
    font-size: 1.2rem;
    line-height: 1.8rem;
    font-family: 'Fedra Sans Alt Pro';
    font-weight: 500;
  }

  &--1 {
    bottom: 17rem;
    left: 30rem;
  }

  &--2 {
    bottom: 28rem;
    left: 50.7rem;
  }

  &--3 {
    bottom: 38.6rem;
    left: 72.4rem;
  }
}

.schema__top {
  position: absolute;
  left: 100%;
  margin-left: 3.5rem;
  top: 15rem;
  font-size: 4.8rem;
  line-height: 6rem;
  white-space: nowrap;
  font-family: 'Fedra Sans Alt Pro';
}

.schema__decor {
  position: absolute;
  width: 18.5rem;
  height: 19.6rem;
  top: -7.5rem;
  right: -6.3rem;
}

.schema__decor-line-1 {
  position: absolute;
  background: #ff1eff;
  width: 1px;
  left: 0;
  bottom: 13.3rem;
  top: 0;
}

.schema__decor-line-2 {
  position: absolute;
  background: #ff1eff;
  height: 1px;
  left: 0;
  top: 0;
  width: 100%;
}

.schema__decor-line-3 {
  position: absolute;
  background: #ff1eff;
  width: 1px;
  right: 0;
  top: 0;
  height: 100%;
}

.schema__it-pin-top {
  width: 3rem;
  height: 3rem;
  position: absolute;
  border-radius: 50%;
  border: 0.6rem solid #ff1eff;
  bottom: 34rem;
  left: 63rem;
  background: #fff;
  z-index: 2;
}

.footer {
  background: #626262;
  .container {
    padding-top: 8rem;
    padding-bottom: 12.5rem;

    @media screen and (max-width: 1023px) {
      padding-top: 2.4rem;
      padding-bottom: 2.4rem;
    }
  }
}

.row__custom {
  margin-left: -2.5rem;
  margin-right: -2.5rem;
}

.col {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.ft__logo {
  width: 15.7rem;
  height: 4.6rem;

  svg {
    display: block;
    width: 100%;
    height: 100%;

    polygon,
    path {
      fill: #fff;
    }
  }
}

.hd__logo {
  width: 15.7rem;
  height: 4.6rem;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 1023px) {
    width: 10.9rem;
    height: 3.2rem;
  }
}

.subscribe__box-title {
  color: #fff;
  font-size: 2.4rem;
  line-height: 1;
  font-family: 'Fedra Sans Alt Pro';
  font-weight: 500;
  margin-bottom: 1rem;

  @media screen and (max-width: 1023px) {
    line-height: 3.6rem;
    text-align: center;
    margin-bottom: 1.6rem;
  }
}

.ft__nav {
  @media screen and (max-width: 1023px) {
    margin-top: 4.8rem;
    width: 24rem;
    margin-left: auto;
    margin-right: auto;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 1.2rem;
    font-size: 0;

    @media screen and (max-width: 1023px) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-right: -0.8rem;
      margin-left: -0.8rem;
      margin-bottom: 3.2rem;
    }

    li {
      display: inline-block;
      vertical-align: top;
      font-size: 1.4rem;
      font-family: 'Fedra Sans Alt Pro';
      font-weight: 500;

      @media screen and (max-width: 1023px) {
        margin-right: 0.8rem !important;
        margin-left: 0.8rem !important;
        font-weight: 400;
      }

      a {
        color: #fff !important;

        &:hover {
          color: #ff1eff !important;
        }
      }

      &:not(:last-child) {
        margin-right: 3.2rem;
      }
    }
  }
}

.ft__rights {
  font-size: 1rem;
  color: #959595;

  @media screen and (max-width: 1023px) {
    text-align: center;
  }
}

.form__field {
  input,
  textarea {
    height: 5rem;
    display: block;
    width: 100%;
    padding: 0 2.4rem;
    color: #fff;
    background: transparent;
    border: 1px solid #fff;
    font-size: 1.2rem;
    font-family: 'Fedra Sans Alt Pro';
    font-weight: 500;

    @media screen and (max-width: 1023px) {
      height: 4rem;
      text-align: center;
      width: 30rem;
      margin-right: auto;
      margin-left: auto;
    }
  }

  textarea {
    resize: none;
    height: 33.5rem;
    padding-top: 1.2rem;
  }

  margin-bottom: 2.4rem;

  @media screen and (max-width: 1023px) {
    margin-bottom: 1.6rem;
  }
}

.subscribe__label {
  white-space: nowrap;
  font-size: 1rem;
  color: #959595;
  font-family: 'Fedra Sans Alt Pro';
  font-weight: 500;
  margin-bottom: 2rem;

  @media screen and (max-width: 1023px) {
    font-size: 1.2rem;
    line-height: 2.4rem;
    margin-bottom: 1.6rem;
    text-align: center;
  }
}

.form__submit {
  height: 5rem;
  min-width: 12.2rem;
  padding: 0 3.5rem;
  cursor: pointer;
  background: #ff1eff;
  font-size: 1.2rem;
  text-align: center;
  justify-content: center;
  font-family: 'Fedra Sans Alt Pro';
  font-weight: 500;
  border: none !important;
  &:hover {
    opacity: 0.75;
  }

  @media screen and (max-width: 1023px) {
    height: 4rem;
    margin-left: auto;
    margin-right: auto;
    min-width: 10.5rem;
    padding: 0 2.4rem;
  }
}

.header {
  position: fixed;
  top: 0;
  z-index: 200;
  left: 0;
  width: 100%;
  background: #fff;
  &--small {
    transform: translateY(-5.6rem);

    .hd__main {
      height: 6.4rem;
    }

    .hd__logo {
      width: 8.7rem;
      height: 3.4rem;
    }
  }
}

.hd__top {
  height: 5.6rem;
  background-color: #eeeeee;

  @media screen and (max-width: 1023px) {
    height: 4rem;
  }

  .container {
    height: 100%;
  }
}

.hd__top-decor {
  width: 0.8rem;
  height: 0.8rem;
  background: #000;
  min-width: 0.8rem;
  margin-right: 10.4rem;
}

.lang__list {
  ul {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      a {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 2.2rem;
        height: 2.2rem;
        font-size: 1rem;
        color: #959595 !important;

        @media screen and (max-width: 1023px) {
          width: 3.2rem;
          height: 3.2rem;
          font-size: 1.2rem;
          color: #959595 !important;
          background: #d9d9d9;
        }
      }

      &.active {
        a {
          background-color: #ffffff;

          @media screen and (max-width: 1023px) {
            color: #000 !important;
            background: #f5f5f5;
          }
        }
      }

      @media screen and (max-width: 1023px) {
        &:not(:last-child) {
          margin-right: 0.4rem;
        }
      }
    }
  }
}

.hd__social-list {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 0;

    li {
      display: inline-block;
      vertical-align: top;
      font-size: 1rem;
      color: #959595;
      font-family: 'Fedra Sans Alt Pro';
      font-weight: 500;
      &:not(:last-child) {
        margin-right: 2.4rem;
      }

      a {
        color: #626262 !important;
      }
    }
  }
}

.hd__social-label {
  font-size: 1rem;
  color: #959595;
  font-family: 'Fedra Sans Alt Pro';
  font-weight: 500;
  margin-right: 2.4rem;
}

.hd__main {
  border-bottom: 1px solid #eeeeee;
  height: 10.4rem;

  @media screen and (max-width: 1023px) {
    height: 8rem;
    border-bottom: 1px solid #f5f5f5;
  }

  .container {
    height: 100%;
  }
}

.hd__nav {
  height: 100%;

  @media screen and (max-width: 1023px) {
    position: fixed;
    opacity: 0;
    visibility: hidden;
  }

  ul {
    height: 100%;
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin-right: -1.8rem;

    li {
      height: 100%;

      a {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 1.8rem;
        color: #000 !important;
        font-size: 1.4rem;
        font-family: 'Fedra Sans Alt Pro';
        font-weight: 500;
        cursor: pointer;

        &:hover {
          background-color: #626262;
          color: #fff !important;
        }
      }

      &.active {
        a {
          background-color: #626262;
          color: #fff !important;
        }
      }
    }
  }
}

.ch__img-sl-box-wrap {
  width: 25.5rem;
  margin-right: auto;
  margin-left: auto;
}

.sc__challenges {
  .scroll__down {
    margin-bottom: 25.1rem;
  }
}

.ch__box-it {
  position: relative;
  width: 1px;
  height: 40.5rem;
  display: flex;
  align-items: center;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    z-index: 4;
    background-color: #bfbfbf;
  }

  &:nth-child(1) {
    .ch__box-it-sl {
      height: 11.6rem;
    }
  }

  &:nth-child(2) {
    .ch__box-it-sl {
      height: 15.4rem;
      animation-delay: 0.05s;
    }
  }

  &:nth-child(3) {
    .ch__box-it-sl {
      height: 18.3rem;
      animation-delay: 0.1s;
    }
  }

  &:nth-child(4) {
    padding-bottom: 2rem;
    .ch__box-it-sl {
      height: 20.4rem;
      animation-delay: 0.15s;
    }
  }

  &:nth-child(5) {
    padding-bottom: 2rem;
    .ch__box-it-sl {
      height: 23rem;
      animation-delay: 0.2s;
    }
  }

  &:nth-child(6) {
    padding-bottom: 2rem;
    .ch__box-it-sl {
      height: 23.6rem;
      animation-delay: 0.25s;
    }
  }

  &:nth-child(7) {
    padding-bottom: 2rem;
    .ch__box-it-sl {
      height: 25rem;
      animation-delay: 0.3s;
    }
  }

  &:nth-child(8) {
    padding-top: 2rem;
    .ch__box-it-sl {
      height: 23.5rem;
      animation-delay: 0.35s;
    }
  }

  &:nth-child(9) {
    padding-top: 2rem;
    .ch__box-it-sl {
      height: 23.8rem;
      animation-delay: 0.4s;
    }
  }

  &:nth-child(10) {
    padding-top: 2rem;
    .ch__box-it-sl {
      height: 19.6rem;
      animation-delay: 0.45s;
    }
  }

  &:nth-child(11) {
    padding-top: 2rem;
    .ch__box-it-sl {
      height: 15.8rem;
      animation-delay: 0.5s;
    }
  }

  &:nth-child(12) {
    .ch__box-it-sl {
      height: 11.6rem;
      animation-delay: 0.55s;
    }
  }

  &:nth-child(13) {
    .ch__box-it-sl {
      height: 11rem;
      animation-delay: 0.6s;
    }
  }

  &:nth-child(14) {
    .ch__box-it-sl {
      height: 17.6rem;
      animation-delay: 0.65s;
    }
  }

  &:nth-child(15) {
    .ch__box-it-sl {
      height: 23.1rem;
      animation-delay: 0.7s;
    }
  }

  &:nth-child(16) {
    .ch__box-it-sl {
      height: 28.6rem;
      animation-delay: 0.75s;
    }
  }
}

.ch__box-left {
  width: 54.5rem;
  min-width: 54.5rem;
  margin-right: 16rem;
}

.ch__box-it-sl {
  left: 50%;
  margin-left: -0.25rem;
  position: relative;
  animation: floating 1.4s infinite;
  z-index: 5;
}

.ch__box-it-sl-handle {
  background-color: #626262;
  width: 0.5rem;
  height: 100%;
}

.ch__box-it-img-box {
  position: absolute;
  margin-bottom: 4rem;
  bottom: 100%;
  left: 50%;
  width: 25.5rem;
  margin-left: -12.75rem;
  overflow: hidden;
  height: 0;
}

.ch__box-it-img {
  height: 25.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.ch__ct-box {
  margin-bottom: 4.2rem;
}

.sl__pager-it {
  width: 2.2rem;
  height: 2.2rem;
  background-color: #eeeeee;
  cursor: pointer;
  font-size: 0.8rem;
  color: #626262;
  font-weight: 300;
  font-family: 'Fedra Sans Pro';

  &.active {
    background-color: #ff1eff;
    color: #fff;
  }

  &:not(:last-child) {
    margin-right: 0.8rem;
  }
}

.ch__box-it {
  &.active {
    &:before {
      background-color: #000;
      top: -11rem;
    }

    .ch__box-it-sl-handle {
      background-color: #ff1eff;
      width: 1rem;
      height: 100%;
    }

    .ch__box-it-sl {
      margin-left: -0.5rem;
    }

    .ch__box-it-img-box {
      height: 30.5rem;
    }
  }
}

.ch__box-right {
  width: 57rem;
  position: relative;

  .sc__txt {
    line-height: 2.4rem;
  }
}

.ch__ct-it-numb {
  height: 9.7rem;
  margin-bottom: 2.6rem;

  img {
    display: block;
    height: 100%;
  }
}

.ch__ct-it-title {
  font-size: 4.8rem;
  font-family: 'Fedra Sans Alt Pro';
  line-height: 1;
  margin-bottom: 1.8rem;
}

.ch__box-nav {
  width: 3.6rem;
  height: 1.9rem;
  position: relative;
  cursor: pointer;
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &:after {
    opacity: 0;
  }

  &:hover {
    &:after {
      opacity: 1;
    }
    &:before {
      opacity: 0;
    }
  }

  &.ch__box-nav--prev {
    position: absolute;
    left: 0;
    bottom: 100%;
    margin-bottom: 3rem;

    &:before {
      background-image: url('../img/arr-1.png');
    }
    &:after {
      background-image: url('../img/arr-1-active.png');
    }
  }

  &.ch__box-nav--next {
    position: absolute;
    left: 0;
    top: 100%;
    margin-top: 3rem;

    &:before {
      background-image: url('../img/arr-2.png');
    }
    &:after {
      background-image: url('../img/arr-2-active.png');
    }
  }
}

@keyframes floating {
  0% {
    transform: translateY(-1rem);
  }
  50% {
    transform: translateY(1rem);
  }
  100% {
    transform: translateY(-1rem);
  }
}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
  html {
    font-size: 0.78125vw;
  }
}

@media screen and (min-width: 1024px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media screen and (max-width: 1023px) {
  html {
    font-size: 2.336vw;
  }

  .hidden-sm-down {
    display: none !important;
  }

  .hd__time {
    padding-left: 3.6rem;
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 1;
    width: 3.2rem;
    text-align: center;
    font-family: 'Din Pro';
  }

  .nav__bars {
    width: 4.8rem;
    height: 4.8rem;
    cursor: pointer;

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .hd__lang {
    height: 4.8rem;
    border-bottom: 1px solid #ece8e8;
    .container {
      height: 100%;
    }
  }

  .news__it-slide-ct {
    padding: 1.6rem;
  }

  .news__it-arrow-sm {
    width: 8rem;
    height: 0.6rem;
    margin-top: 0.9rem;
    min-width: 8rem;

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .news__it-img {
    position: relative;
  }

  .news__it-swipe-info {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.66);
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-family: 'Fedra Sans Pro';
    font-weight: 300;
  }

  .news__sl-box {
    margin-bottom: 3.2rem;
  }

  .news__it-title {
    color: #46494a;
  }

  .news__it-bottom {
    height: 3.6rem;
    margin-top: 0.8rem;
  }

  .news__it-slide--1 {
    .news__it-title {
      margin-bottom: 0;
    }
  }

  .news__it-slide--2 {
    .news__it-slide-ct {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      height: 53.6rem;
      position: relative;
    }

    .news__it-swipe-info {
      bottom: 22.9rem;
      top: auto;
      height: auto;
    }

    .news__it-title {
      color: #fff;
    }
  }

  .news__sl-box {
    position: relative;
    margin-left: -3.2rem;
    margin-right: -3.2rem;

    .slick-dots {
      position: absolute;
      left: 0;
      bottom: 6.8rem;
      z-index: 3;
      width: 100%;
      display: flex !important;
      justify-content: center;
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        button {
          font-size: 0;
          width: 1.2rem;
          height: 1.2rem;
          border: none;
          background: #d9d9d9;
        }

        &.slick-active {
          button {
            background: #ff1eff;
          }
        }

        &:not(:last-child) {
          margin-right: 0.8rem;
        }
      }
    }
  }

  .news__it-slide {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }

  .news__it-slide--3 {
    .news__it-slide-ct {
      height: 53.6rem;
      background: #f7f7f7;
      position: relative;
    }

    .news__it-swipe-info {
      color: #959595;
      bottom: 16.8rem;
      height: auto;
      top: auto;
    }
  }

  .ab__sl-row {
    margin-right: -3.2rem;
    margin-left: -3.2rem;
  }

  .ft__logo {
    width: 10.9rem;
    height: 3.2rem;
    margin-bottom: 3.2rem;
    margin-left: auto;
    margin-right: auto;
  }

  .sc__challenges {
    display: none !important;
  }

  .schema__target {
    position: relative;
    padding: 1.6rem 3.2rem;
    margin-left: -3.2rem;
    margin-right: -3.2rem;
    background: #f5f5f5;
    top: 0;
    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 2.7rem 0 0 2.7rem;
      border-color: transparent transparent transparent #f5f5f5;
      left: 3.2rem;
      bottom: 100%;
    }
  }

  .schema__target-descr {
    background: transparent;
    width: 100%;
    height: auto;
    padding: 0;
    font-size: 1.4rem;
    line-height: 2.4rem;
    &:before {
      display: none;
    }
  }

  .schema__box {
    width: 100%;
  }

  .schema__box-wrap {
    position: relative;
    height: 35.4rem;
    padding-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
  }
  .schema__img {
    width: 100%;
    img {
      display: block;
      width: 100%;
    }
  }

  .schema__box-top {
    width: 100%;
  }

  .activities__list {
    margin-left: 0;
  }

  .schema__box-top-title {
    font-size: 3.2rem;
    line-height: 4.2rem;
    font-family: 'Fedra Sans Pro';
    font-weight: 300;
  }

  .schema__target-title {
    padding-left: 0;
    margin-bottom: 0.8rem;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.4rem;
  }

  .activities__it {
    width: 100%;
    display: flex;
    align-items: flex-start;

    &:not(:last-child) {
      margin-bottom: 1.6rem;
    }
  }

  .activities__it:first-child {
    margin-left: 0;
  }

  .activities__it-numb {
    text-align: center;
    justify-content: center;
    margin-right: 1.6rem;
  }

  .activities__list {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  .schema__box {
    margin-bottom: 3.2rem;
  }

  .schema__it-mb {
    width: 2.2rem;
  }

  .schema__it-mb-1 {
    position: absolute;
    bottom: 8rem;
    left: 4.3rem;

    .schema__it-mb-title {
      margin-left: -3rem;
      white-space: nowrap;
    }
  }

  .schema__it-mb-pin {
    width: 2.2rem;
    height: 2.2rem;
    margin-top: 1.6rem;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      border-radius: 50%;
      top: 0;
      bottom: 0;
      background: #959595;
    }

    &:after {
      content: '';
      position: absolute;
      width: 4.6rem;
      height: 4.6rem;
      border: 0.5rem solid #ff1eff;
      left: 50%;
      top: 50%;
      border-radius: 50%;
      margin-left: -2.3rem;
      margin-top: -2.3rem;
      transform: scale(1.05);
      opacity: 0;
    }
  }

  .schema__it-mb-title {
    font-weight: 350;
    font-size: 1rem;
    line-height: 1.6rem;
    font-family: 'Fedra Sans Pro';
    color: #959595;
  }

  .schema__it-mb-2 {
    position: absolute;
    bottom: 14rem;
    left: 16.7rem;

    .schema__it-mb-title {
      width: 12.4rem;
      text-align: center;
      left: 50%;
      position: relative;
      margin-left: -6.2rem;
    }
  }

  .schema__it-mb-3 {
    position: absolute;
    bottom: 20.2rem;
    left: 29.4rem;

    .schema__it-mb-title {
      width: 11.7rem;
      text-align: right;
      margin-left: -6rem;
    }
  }
}

.post__hd {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#46494a+0,46494a+100&0+0,1+100 */
    background: -moz-linear-gradient(
      top,
      rgba(70, 73, 74, 0) 0%,
      rgba(70, 73, 74, 1) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(70, 73, 74, 0) 0%,
      rgba(70, 73, 74, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(70, 73, 74, 0) 0%,
      rgba(70, 73, 74, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0046494a', endColorstr='#46494a',GradientType=0 ); /* IE6-9 */
  }
}

.post__img {
  img {
    display: block;
    width: 100%;

    @media screen and (max-width: 1023px) {
      height: 100%;
      object-fit: cover;
    }
  }

  @media screen and (max-width: 1023px) {
    height: 100%;
  }
}

.content--post,
.content--team,
.content--contacts {
  .container {
    padding-top: 16.6rem;

    @media screen and (max-width: 1023px) {
      padding-top: 15.8rem;
    }
  }
}

.post__hd {
  margin-left: -5.5rem;
  margin-right: -5.5rem;
  margin-bottom: 2.5rem;
  overflow: hidden;
  height: 70rem;

  &.active {
    height: 38rem;
  }

  @media screen and (max-width: 1023px) {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 3.2rem;
    height: 38.8rem !important;
  }
}

.post__hd-ct {
  position: absolute;
  z-index: 5;
  padding: 5.5rem;
  bottom: 0;
  left: 0;
  width: 100%;

  @media screen and (max-width: 1023px) {
    padding: 3.2rem;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
  }
}

.seo__item {
  & > * {
    font: inherit !important;
    margin: 0 !important;
  }
}

.post__title {
  font-size: 3.6rem;
  line-height: 5rem;
  color: #fff;
  font-weight: 500;
  font-family: 'Fedra Sans Alt Pro';
  max-width: 57.5rem;

  @media screen and (max-width: 1023px) {
    font-size: 3.1rem;
    line-height: 4rem;
  }
}

.post__load-link {
  position: relative;
  width: 0.8rem;
  height: 6.9rem;
  margin: 0 auto;
  cursor: pointer;

  background: url('../img/load-arrow.png') no-repeat center;
  background-size: contain;

  &:before {
    content: '';
    position: absolute;
    left: -1rem;
    right: -1rem;
    top: 0;
    bottom: 0;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.post__info {
  margin-bottom: 2rem;
  .news__it-likes {
    margin-right: 1.8rem;
    padding-right: 1.8rem;
    position: relative;

    @media screen and (max-width: 1023px) {
      width: auto;
    }

    &:after {
      content: '';
      position: absolute;
      width: 1px;
      background: rgba(0, 0, 0, 0.25);
      right: 0;
      top: 50%;
      height: 3.4rem;
      margin-top: -1.7rem;

      @media screen and (max-width: 1023px) {
        background: #000;
        height: 1.6rem;
        margin-top: -0.8rem;
      }
    }
  }

  .news__it-comments {
    color: #000;
  }
}

.post__text {
  h2 {
    font-size: 3.6rem;
    line-height: 4.5rem;
    font-weight: 400;
    font-family: 'Fedra Sans Pro';
    color: #46494a;
    margin: 0 0 2.4rem 0;

    @media screen and (max-width: 1023px) {
      font-size: 1.8rem;
      line-height: 3.2rem;
      margin: 0 0 3.2rem 0;
      font-family: 'Fedra Sans Pro';
    }
  }

  blockquote {
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 3rem;
    padding-left: 3rem;
    border-left: 0.4rem solid #00ffff;
    margin: 2.4rem 0;
  }

  h3 {
    font-family: 'Fedra Sans Alt Pro';
    margin: 0 0 3.2rem 0;
    font-size: 1.6rem;
    line-height: 3rem;
    font-weight: bold;
  }

  p {
    font-size: 1.4rem;
    line-height: 2.4rem;
    font-weight: 300;
    font-family: 'Fedra Sans Pro';
    margin: 0 0 3rem 0;

    @media screen and (max-width: 1023px) {
      font-size: 1.4rem;
      line-height: 2.4rem;
      font-weight: 300;
      margin: 0 0 3.2rem 0;
      font-family: 'Fedra Sans Alt Pro';
    }
  }

  img {
    display: block;
    max-width: 100%;
    margin-bottom: 3.6rem;
  }

  picture {
    display: block;
    max-width: 100%;
    margin-bottom: 3.6rem;
  }

  h2,
  h3,
  p,
  li {
    a {
      color: #46494a !important;
      border-bottom: 2px solid #00ffff;
      &:hover {
        border-bottom: 2px solid transparent;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0 0 3.6rem 0;

    @media screen and (max-width: 1023px) {
      margin: 0 0 3.2rem 0;
    }

    li {
      position: relative;
      font-size: 1.6rem;
      font-weight: 500;
      font-family: 'Fedra Sans Alt Pro';
      line-height: 3.4rem;
      padding-left: 2.6rem;

      @media screen and (max-width: 1023px) {
        font-size: 1.4rem;
        line-height: 2.4rem;
        padding-left: 1.6rem;

        &:not(:last-child) {
          margin-bottom: 0.8rem;
        }
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 1.2rem;
        width: 0.8rem;
        height: 0.8rem;
        background-color: #ff1eff;

        @media screen and (max-width: 1023px) {
          top: 0.6rem;
        }
      }
    }
  }

  & > &:last-child {
    margin-bottom: 0 !important;
  }

  & > *:first-child {
    margin-top: 0 !important;
  }
}

.post__text-box {
  & > .row {
    margin-bottom: -3.6rem;
  }
}

.post__body {
  .news__it-social {
    position: relative;
    left: 0;
    bottom: 0;

    @media screen and (max-width: 1023px) {
      margin-left: 0;
    }
  }
}

.post__actions {
  padding-top: 2.6rem;
  border-top: 1px solid #a2a4a4;
  margin-bottom: 4.8rem;
  margin-top: 2rem;

  a {
    width: 3.8rem;

    @media screen and (max-width: 1023px) {
      width: 3.2rem;
      height: 3.2rem;
    }

    &:not(:last-child) {
      margin-right: 3rem;

      @media screen and (max-width: 1023px) {
        margin-right: 1.6rem;
      }
    }

    &:hover {
      .post__action-icon {
        background: #46494a;
      }

      path {
        fill: #fff;
      }
    }
  }

  @media screen and (max-width: 1023px) {
    padding-top: 0;
    border-top: none;
    margin-bottom: 2.4rem;
    margin-top: 0;
    justify-content: flex-end !important;
  }
}

.post__action-title {
  margin: 0 -1rem;
  text-align: center;
  font-size: 1.1rem;
  font-family: 'Fedra Sans Alt Pro';
  color: #46494a;
  margin-top: 0.8rem;

  @media screen and (max-width: 1023px) {
    display: none !important;
  }
}

.post__action-icon {
  width: 3.8rem;
  height: 3.8rem;
  background: #eeeeee;

  @media screen and (max-width: 1023px) {
    width: 3.2rem;
    height: 3.2rem;
  }
}

.icon__share {
  width: 1.7rem;
  height: 1.9rem;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.icon__download {
  width: 1.7rem;
  height: 1.9rem;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.icon__print {
  width: 1.7rem;
  height: 1.7rem;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.hidden {
  display: none !important;
}

.post__scroll-box {
  height: auto;
  margin-left: -11rem;
  margin-right: -11rem;

  &.active {
    height: calc(100vh - 16.6rem);
  }

  @media screen and (max-width: 1023px) {
    margin: 0;
  }
}

.post__author {
  font-family: 'Fedra Sans Alt Pro';
  font-size: 1.1rem;
}

.post__scroll-inside {
  padding-left: 11rem;
  padding-right: 11rem;

  @media screen and (max-width: 1023px) {
    padding: 0;
  }
}

.rcs-custom-scroll {
  min-height: 0;
  min-width: 0;
}

.rcs-custom-scroll .rcs-outer-container {
  overflow: hidden;
}

.rcs-custom-scroll .rcs-outer-container .rcs-positioning {
  position: relative;
}

.rcs-custom-scrollbar {
  opacity: 1 !important;
}

.rcs-custom-scroll .rcs-outer-container:hover .rcs-custom-scrollbar {
  opacity: 1;
  transition-duration: 0.2s;
}

.rcs-custom-scroll .rcs-inner-container {
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.rcs-custom-scroll .rcs-inner-container:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.05) 60%,
    rgba(0, 0, 0, 0) 100%
  );
  pointer-events: none;
  transition: height 0.1s ease-in;
  will-change: height;
}

.rcs-custom-scroll .rcs-inner-container.rcs-content-scrolled:after {
  height: 5px;
  transition: height 0.15s ease-out;
}

.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-inner-container {
  user-select: none;
}

.rcs-custom-scroll .rcs-custom-scrollbar {
  position: absolute;
  top: 12.4rem;
  height: 100%;
  width: 0.4rem;
  right: 1.3rem;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.4s ease-out;
  padding: 6px 0;
  box-sizing: border-box;
  will-change: opacity;
  pointer-events: none;
}

.rcs-custom-scroll .rcs-custom-scrollbar.rcs-custom-scrollbar-rtl {
  right: auto;
  left: 3px;
}

.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-custom-scrollbar {
  opacity: 1;
}

.rcs-custom-scroll .rcs-custom-scroll-handle {
  position: absolute;
  width: 100%;
  top: 0;
}

.rcs-custom-scroll .rcs-inner-handle {
  height: calc(100% - 1.2rem);
  background-color: #46494a;
  border-radius: 1px;
}

.post__scroll-box {
  position: relative;
}

.post__close {
  position: absolute;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  top: 2.4rem;
  right: 0;
  z-index: 4;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.post__body {
  &:not(.active) {
    display: none !important;

    @media screen and (max-width: 1023px) {
      display: block !important;
    }
  }
}

.team__box {
  margin-top: 3rem;

  &-row {
    margin-right: -2.5rem;
    margin-left: -2.5rem;
  }
}

.team__it-arr {
  width: 6.9rem;
  margin-top: 1.4rem;

  img {
    display: block;
    width: 100%;
  }
}

.team__it {
  padding-left: 2.5rem;
  padding-right: 2.5rem;

  &-ct {
    position: absolute;
    background: #fff;
    right: 0;
    height: auto;
    width: 26.4rem;
    padding: 4rem 0;
    bottom: 0;

    @media screen and (max-width: 1023px) {
      width: 19rem;
    }

    &-bl {
      width: 100%;
      padding-left: 4rem;
      padding-right: 4rem;

      @media screen and (max-width: 1023px) {
        padding-left: 2.4rem;
        padding-right: 1.6rem;
      }
    }
  }

  &-bl {
    position: relative;
    font-size: 0;

    img {
      display: block;
      width: 100%;
    }
  }
}

.team__box {
  position: relative;
}

.sl__nav {
  position: absolute;
  width: 1.9rem;
  height: 3.6rem;
  top: 50%;
  margin-top: -1.8rem;
  z-index: 4;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &:after {
    opacity: 0;
  }

  &:hover {
    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 1;
    }
  }

  &-prev {
    left: -5.5rem;

    &:before {
      background-image: url('../img/arr-left.png');
    }

    &:after {
      background-image: url('../img/arr-left-act.png');
    }
  }

  &-next {
    right: -5.5rem;

    &:before {
      background-image: url('../img/arr-right.png');
    }

    &:after {
      background-image: url('../img/arr-right-act.png');
    }
  }
}

.team__it-position {
  font-size: 1.4rem;
  line-height: 2.4rem;
}

.team__it-name {
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 140%;
  font-family: 'Fedra Sans Pro';
  margin-bottom: 1.4rem;

  @media screen and (max-width: 1023px) {
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 1.4rem;
  }
}

.content--subscribe {
  .container {
    padding-top: 24.5rem;

    @media screen and (max-width: 1023px) {
      padding-top: 18.4rem;
    }
  }
}

.subscribe__form-header {
  margin-bottom: 5.2rem;

  @media screen and (max-width: 1023px) {
    margin-bottom: 2.2rem;
  }
}

.subscribe__form-title {
  font-size: 4.8rem;
  font-family: 'Fedra Sans Alt Pro';
  margin-bottom: 0.4rem;

  @media screen and (max-width: 1023px) {
    font-size: 2.4rem;
    line-height: 3.6rem;
    margin-bottom: 1.6rem;
    text-align: center;
  }
}

.subscribe__form-subtitle {
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #46494a;

  @media screen and (max-width: 1023px) {
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    line-height: 3rem;
  }
}

.subscribe__icon-box {
  height: 100%;

  @media screen and (max-width: 1023px) {
    height: 19.6rem;
    margin-bottom: 2.4rem;
  }

  img {
    display: block;
    width: 23.3rem;

    @media screen and (max-width: 1023px) {
      width: 15.2rem;
    }
  }
}

.form__it,
.form__col {
  .form__field {
    margin-bottom: 0;
    input,
    textarea {
      color: #46494a;
      border: 1px solid #000;

      @media screen and (max-width: 1023px) {
        height: 4.8rem;
      }

      &:focus {
        border-color: #ff1eff;
      }
    }

    textarea {
      @media screen and (max-width: 1023px) {
        height: 8rem;
        margin-bottom: 3.2rem;
      }
    }
  }
}

.form__hint {
  font-size: 1.1rem;
  color: #959595;
  line-height: 2rem;

  @media screen and (max-width: 1023px) {
    margin-bottom: 2.4rem;
  }
}

.form__it {
  margin-bottom: 4.5rem;

  @media screen and (max-width: 1023px) {
    margin-bottom: 2.2rem;
  }
}

.form__list {
  margin-bottom: -4.5rem;

  @media screen and (max-width: 1023px) {
    margin-bottom: -2.2rem;
  }

  .form__submit {
    width: 100%;
    color: #fff !important;

    @media screen and (max-width: 1023px) {
      height: 4.8rem;
    }
  }
}

.checkbox__wrap {
  label {
    font-weight: normal;
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;

    input {
      position: absolute;
      opacity: 0;
      visibility: hidden;

      &:checked {
        & ~ .checkbox__decor {
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
}

.checkbox__decor {
  width: 2.2rem;
  height: 2.2rem;
  min-width: 2.2rem;
  margin-right: 2.4rem;
  border: 1px solid #000;
  cursor: pointer;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 1rem;
    height: 1rem;
    background-color: #ff1eff;
    margin-left: -0.5rem;
    margin-top: -0.5rem;
    opacity: 0;
  }
}

.content--subscribe {
  position: relative;

  .post__close {
    right: 5rem;
    top: 20rem;
  }
}

.checkbox__title {
  font-size: 1rem;
  color: #46494a;
  cursor: pointer;
}

.map {
  height: 78.7rem;
  margin-top: -4.4rem;

  @media screen and (max-width: 1023px) {
    height: 70rem;
    margin-top: 6rem;
    margin-left: -3.2rem;
    margin-right: -3.2rem;
  }
}

.content--contacts {
  .container {
    padding-bottom: 0 !important;
  }
  .subscribe__form-title {
    font-weight: 400;
  }

  .subscribe__form-box {
    padding-top: 3rem;
  }
}

.map__txt {
  width: 20rem;
  height: 20rem;
  background-color: #000;
}

.map__marker {
  position: relative;
}

.map__marker-point {
  width: 2.4rem;
  height: 2.4rem;
  background-color: #ff1eff;
}

.map__marker-balloon {
  position: absolute;
  left: 100%;
  top: 100%;
  background: #fff;
  width: 27.4rem;
  margin-left: 2.4rem;
}

.map__marker-balloon-ct {
  padding: 2.8rem 2.8rem 2.2rem;
}

.map__marker-balloon-decor {
  width: 5rem;
  height: 5rem;
  border-left: 0.4rem solid #46494a;
  border-top: 0.4rem solid #46494a;
  margin-bottom: 1.6rem;
}

.map__marker-address {
  font-size: 1.4rem;
  font-family: 'Fedra Sans Alt Pro';
  font-weight: bold;
  line-height: 2.4rem;
  margin-bottom: 0.4rem;
}

.map__marker-hint {
  font-size: 1.2rem;
  line-height: 2.4rem;
}

.map__btn {
  height: 5.2rem;
  color: #fff !important;
  background-color: #ff1eff;
  font-family: 'Fedra Sans Alt Pro';
  font-weight: bold;
  font-size: 1.4rem;
  padding: 0 3rem;
}

.icon-phone {
  width: 2rem;
  height: 2rem;

  svg {
    display: block;
    width: 100%;
    height: 100%;

    path {
      fill: #fff;
    }
  }
}

.section {
  padding-top: 6.4rem;
  overflow: hidden;
}

.sc__promo {
  padding-top: 20rem;
}

/*animation*/

.news__it,
.load__more-wrap {
  opacity: 0;
  transform: translateY(5rem);
}

.news__net {
  margin-top: 8rem;
}

.section__scroll-box {
  position: absolute;
  left: 50%;
  margin-left: -58.4rem;
  top: 4rem;
}

.section .container {
  position: relative;
}

.sc__challenges {
  justify-content: flex-end !important;
  padding-bottom: 1.5rem;
}

.ab__row {
  padding-top: 2rem;
}

.ab__box {
  margin-top: 2rem;
}

.sc__mission {
  .sc__promo-decor:after {
    top: auto;
    bottom: 1.9rem;
    right: 1.7rem;
    border-right: 0.4rem solid #fff;
    border-bottom: 0.4rem solid #fff;
    border-top: none;
  }
}

.fp-section {
  overflow: hidden !important;
  display: flex !important;
  justify-content: center !important;

  &.sc__challenges {
    justify-content: flex-end !important;
  }
}

.page-not-front {
  .wrapper {
    height: auto !important;
  }
}

.sc__footer {
  padding-top: 0 !important;
}

.sc__contacts {
  .container {
    padding-top: 15.8rem;

    @media screen and (max-width: 1023px) {
      padding-top: 0;
    }
  }
}

.content--post {
  .container {
    padding-top: 15.2rem;
  }
}

.modal__custom {
  position: fixed;
  top: 0;
  height: 100vh;
  z-index: 300;
  left: 0;
  width: 100vw;
  background: rgba(#000000, 0.9);
  opacity: 0;
  visibility: hidden;
  text-align: center;
  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-right: -0.35em;
    height: 100%;

    @media screen and (max-width: 1023px) {
      display: none !important;
    }
  }
  &[data-show='true'] {
    opacity: 1;
    visibility: visible;
    .modal__box {
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0);
    }
  }
}

.modal__close {
  position: absolute;
  width: 2.6rem;
  top: 1rem;
  right: 1rem;
  z-index: 3;
  height: 2.6rem;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }

  path {
    fill: #000;
  }
}

.modal__box-header {
  margin-bottom: 3.4rem;
}

.modal__box-title {
  font-size: 3.2rem;
  text-align: center;
  font-family: 'Fedra Sans Alt Pro';
}

.modal__box {
  width: 48rem;
  background: #fff;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateY(-40px);
  -moz-transform: translateY(-40px);
  -ms-transform: translateY(-40px);
  -o-transform: translateY(-40px);
  transform: translateY(-40px);
  margin-top: 5rem;
  margin-bottom: 5rem;
  overflow: hidden;
  color: #000;
  padding: 4rem 4rem 6rem;

  @media screen and (max-width: 1023px) {
    min-height: 100vh;
    width: 100% !important;
    margin-top: 0;
    border-radius: 0;
    border: none;
    margin-bottom: 0;
    padding: 6.8rem 2.6rem 4rem;
  }
}

.hist__data {
  .row {
    margin-right: -2rem;
    margin-left: -2rem;
  }
}

.hist__col {
  width: 25%;
  padding-right: 2rem;
  padding-left: 2rem;

  @media screen and (max-width: 1023px) {
    width: 100%;

    &:last-child {
      margin-top: 1.6rem;
    }
  }

  img {
    display: block;
    width: 100%;
  }
}

.hist__title {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 1.4rem;
  font-family: 'Fedra Sans Alt Pro';

  @media screen and (max-width: 1023px) {
    margin-bottom: 1.6rem;
  }
}

.hist__descr {
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-family: 'Fedra Sans Alt Pro';

  p {
    &:not(:last-child) {
      margin-bottom: 1.6rem;
    }
  }

  @media screen and (max-width: 1023px) {
    line-height: 2.4rem;

    p {
      &:not(:last-child) {
        margin-bottom: 1.6rem;
      }
    }
  }
}

.hist__box {
  background: #eeeeee;
  padding: 1.6rem;
  margin-bottom: 4rem;
}

.hist__subtitle {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: 'Fedra Sans Alt Pro';
}

.hist__sep {
  margin: 2.4rem 0;
  width: 5.7rem;
  height: 0.5rem;
  background: #00ffff;
}

.hist__data {
  position: relative;

  /*&:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 10.2rem;
    height: 9.9rem;
    border-bottom: 0.4rem solid #46494a;
    border-right: 0.4rem solid #46494a;

    @media screen and (max-width: 1023px) {
      width: 4rem;
      height: 4rem;
      position: relative;
      margin-left: auto;
      margin-right: 0;
      border-width: 2px;
      margin-top: 1.6rem;
      display: block;
    }
  }*/
}

.hist__decor {
  position: relative;
  width: 2.8rem;
  height: 2.8rem;
  background-color: #ff1eff;
  top: 100%;
  left: 0.16rem;
  margin-top: 1.4rem;

  /*@media screen and (max-width: 1023px) {
    margin-right: 0;
    margin-left: auto;
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    position: relative;
    top: auto;
  }*/

  &:after {
    content: '';
    right: 0.6rem;
    top: 0.6rem;
    position: absolute;
    border-right: 0.2rem solid #fff;
    border-top: 0.2rem solid #fff;
    width: 0.9rem;
    height: 0.9rem;
  }
}

.hist__col--4 .hist__descr {
  margin-bottom: 8rem;
}
@media screen and (max-width: 1023px) {
  .hist__col--4 .hist__descr {
    margin-bottom: 1.8rem;
  }
}

.sc__quote {
  .section__scroll-box {
    right: 50%;
    margin-right: -58.4rem;
  }

  .scroll__down-arrow {
    margin-right: 1.4rem;
  }
}

.mission__logo {
  width: 21rem;
  height: 6rem;
  margin: 0 auto 4rem;

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.sc__why {
  .sc__ct {
    text-align: left;
    padding-left: 16rem;

    @media screen and (max-width: 1023px) {
      padding-left: 0;
    }
  }

  @media screen and (max-width: 1023px) {
    height: auto !important;
    padding-top: 14.4rem !important;

    .section__scroll-box {
      top: 3.2rem;
    }

    .sc__title {
      font-size: 3.2rem;
      line-height: 4.2rem;
    }
  }

  .sc__header {
    margin-bottom: 4rem;

    @media screen and (max-width: 1023px) {
      margin-bottom: 2.4rem;
    }
  }

  .sc__title {
    text-align: left;
    color: #46494a;
  }
}

.why__sl-row {
  margin-left: -1.2rem;
  margin-right: -1.2rem;

  @media screen and (max-width: 1023px) {
    margin-left: -0.8rem;
    margin-right: -0.8rem;
  }
}

.why__sl-it {
  padding-right: 1.2rem;
  padding-left: 1.2rem;

  @media screen and (max-width: 1023px) {
    padding-right: 0.8rem;
    padding-left: 0.8rem;
  }
}

.why__sl-it-img {
  width: 25.5rem;
  min-width: 25.5rem;
  position: relative;
  margin-right: 4rem;

  @media screen and (max-width: 1023px) {
    width: 100%;
    min-width: 100%;
    height: 26rem;
  }

  img {
    display: block;
    @media screen and (max-width: 1023px) {
      height: 100%;
      object-fit: cover;
    }
    width: 100%;
  }

  &:after {
    width: 16rem;
    height: 27.5rem;
    background: url('../img/why-decor.svg') no-repeat center;
    background-size: contain;
    left: 4.8rem;
    top: 0;
    position: absolute;
    content: '';

    @media screen and (max-width: 1023px) {
      width: 22.6rem;
      height: 23.4rem;
      background: url('../img/why-decor-2.svg') no-repeat center;
      background-size: contain;
      left: 6.8rem;
      top: 0;
    }
  }
}

.why__sl-it-ct {
  padding-top: 6rem;

  @media screen and (max-width: 1023px) {
    padding-top: 5.6rem;
    height: 23rem;
  }
}

.why__sl-it-title {
  margin-bottom: 2rem;
  font-weight: 500;
  font-size: 1.6rem;
  font-family: 'Fedra Sans Alt Pro';
  line-height: 2.4rem;
}

.why__sl-it-numb {
  margin-bottom: 2.7rem;
  font-weight: 400;
  font-family: 'Inter';
  font-size: 1rem;
  line-height: 1.2rem;
}

.why__sl-it-sep {
  width: 5.7rem;
  height: 0.5rem;
  background: #00ffff;
}

.why__sl-box {
  margin-bottom: 7.7rem;
  position: relative;

  @media screen and (max-width: 1023px) {
    padding-right: 6.4rem;
    margin-bottom: 2.3rem;
  }

  &:before {
    content: '';
    top: 0;
    bottom: 0;
    right: 100%;
    background: #fff;
    width: 100rem;
    z-index: 2;
    position: absolute;
  }

  &:after {
    content: '';
    position: absolute;
    background: #eeeeee;
    height: 1px;
    left: 0;
    bottom: -9.7rem;
    width: 400rem;
  }
}

.why__sl-it-bl {
  opacity: 1;
  will-change: opacity;
  -webkit-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;

  @media screen and (max-width: 1023px) {
    flex-wrap: wrap;
  }
}

.sc__why {
  .slick-list {
    overflow: visible;
  }

  .slick-slide {
    &:not(.slick-active) {
      .why__sl-it-bl {
        opacity: 0.2;
      }
    }
  }

  @media screen and (max-width: 1023px) {
    padding-bottom: 3.2rem !important;
    border-bottom: 2px solid #f5f5f5;

    .sl__nav-list {
      padding: 0;
      margin-right: auto;
    }
  }
}

.sl__nav-list {
  display: flex;
  padding: 0 4.4rem;
  background: #fff;
  position: relative;
  z-index: 3;
  margin-right: 3.6rem;

  .sl__nav {
    position: relative;
    top: 0;
    margin-top: 0;
    left: 0;
    right: 0;

    &:not(:last-child) {
      margin-right: 4rem;
    }
  }
}

.quote__box {
  @media screen and (max-width: 1023px) {
    flex-wrap: wrap;
  }

  .team__it-bl {
    width: 49.7rem;
    min-width: 49.7rem;
    margin-right: 4rem;

    @media screen and (max-width: 1023px) {
      width: 100%;
      min-width: 100%;
      margin-right: 0;
      margin-bottom: 2.4rem;
    }
  }

  .team__it-ct-bl {
    padding-left: 4rem;
    padding-right: 4rem;

    @media screen and (max-width: 1023px) {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
    }
  }

  .team__it-ct {
    height: auto;
    padding: 4rem 0;

    @media screen and (max-width: 1023px) {
      width: 20.8rem;
      padding: 2.4rem 0;
    }
  }

  .team__it-name {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.4rem;
  }
}

.quote__ct {
  padding: 4rem 6rem 0;

  @media screen and (max-width: 1023px) {
    padding: 0;
  }
}

.quote__header {
  margin-bottom: 4rem;

  @media screen and (max-width: 1023px) {
    margin-bottom: 2.4rem;
    flex-wrap: wrap;
  }
}

.quote__icon {
  width: 8rem;
  height: 6rem;
  margin-bottom: 2.4rem;

  @media screen and (max-width: 1023px) {
    margin-right: 0;
    width: 10.7rem;
    height: 8rem;
    margin-bottom: 4rem;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.quote__text {
  font-weight: 350;
  font-size: 1.4rem;
  line-height: 2.4rem;

  p {
    margin: 0 0 1.4rem 0;

    @media screen and (max-width: 1023px) {
      margin: 0 0 2.4rem 0;
    }
  }

  & > *:last-child {
    margin-bottom: 0 !important;
  }
}

.quote__title {
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 140%;
  margin-bottom: 4rem;
  font-family: 'Fedra Sans Alt Pro';

  @media screen and (max-width: 1023px) {
    width: 100%;
    margin-bottom: 2.4rem;
  }
}

.prin__box-left {
  width: 63rem;
  min-width: 63rem;
  display: flex;
  margin-right: 4rem;
  height: 63.8rem;

  @media screen and (max-width: 1023px) {
    height: 36rem;
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 2.4rem;
  }
}

.prin__slide {
  width: 21.2%;
  will-change: width, filter;
  -webkit-transition: width 0.5s ease, filter 0.5s ease;
  transition: width 0.5s ease, filter 0.5s ease;
  filter: grayscale(100%);

  &.active {
    width: 57.6%;
    filter: grayscale(0%);
  }
  position: relative;
  overflow: hidden;

  height: 100%;
}

.prin__slide-img {
  width: 36.6rem;
  left: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);

  @media screen and (max-width: 1023px) {
    width: 21.6rem;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.prin__it-title {
  font-weight: 400;
  font-size: 4.8rem;
  line-height: 6rem;
  font-family: 'Fedra Sans Alt Pro';
  margin-bottom: 2.4rem;

  @media screen and (max-width: 1023px) {
    font-size: 2.4rem;
    line-height: 3.6rem;
  }
}

.prin__box-ct {
  padding-top: 5.2rem;

  @media screen and (max-width: 1023px) {
    padding-top: 0;
  }
}

.prin__numb {
  height: 10.1rem;
  margin-bottom: 3.5rem;

  @media screen and (max-width: 1023px) {
    margin-bottom: 2.4rem;
  }

  img {
    display: block;
    height: 100%;
  }
}

.det__list {
  .row {
    margin-right: -0.4rem;
    margin-left: -0.4rem;
    margin-bottom: -2.4rem;

    @media screen and (max-width: 1023px) {
      margin-bottom: -1.6rem;
    }
  }

  &--4 {
    .det__it {
      width: 25%;
    }
  }
}

.det__it {
  padding-right: 0.4rem;
  padding-left: 0.4rem;
  width: 33.3333%;
  margin-bottom: 2.4rem;

  @media screen and (max-width: 1023px) {
    margin-bottom: 1.6rem;
    width: 50% !important;
  }
}

.det__it-bl {
  padding-top: 1.6rem;
  position: relative;

  &:before {
    content: '';
    left: 0;
    top: 0;
    width: 0.8rem;
    height: 0.8rem;
    background: #ff1eff;
    position: absolute;
  }

  font-family: 'Fedra Sans Pro';
  font-weight: 350;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #46494a;
}

.prin__it--3 {
  .det__it-bl {
    font-size: 1.2rem;
    line-height: 1.9rem;
  }
}

.prin__box {
  height: 63.8rem;
  position: relative;

  @media screen and (max-width: 1023px) {
    flex-wrap: wrap;
    height: auto;
  }

  .sl__nav-list {
    position: absolute;
    right: 4rem;
    bottom: 2rem;
    margin-right: 0;
    padding-right: 0;

    @media screen and (max-width: 1023px) {
      position: relative;
      right: auto;
      left: 0;
      bottom: 0;
      padding-left: 0;
    }
  }
}

.prin__box-ct {
  width: 50rem !important;

  @media screen and (max-width: 1023px) {
    width: 100% !important;
  }
}

.ind__it {
  height: 22.7rem;
  border-right: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  width: 20%;
  min-width: 20%;
  padding: 3.6rem 3rem 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  @media screen and (max-width: 1023px) {
    width: 50%;
    min-width: 50%;
    height: 18rem;
    padding: 2.4rem 1.6rem;

    &:nth-child(2n) {
      border-left: 1px solid #eeeeee;
    }

    &:last-child {
      width: 100%;
      padding: 5rem 4rem;
      align-items: flex-start;

      .ind__it-title {
        max-width: 15.4rem;
      }
    }
  }
}

.ind__it-title {
  text-align: center;
}

.ico-ind {
  width: 11.7rem;
  height: 11.7rem;
  background-size: cover;
  margin-bottom: 1.3rem;
}
@media screen and (max-width: 1023px) {
  .ico-ind {
    width: 8.9rem;
    height: 8.9rem;
    margin-bottom: 1rem;
  }
}
.ico-ind-1 {
  background-image: url('../img/ico-ind-1.svg');
}
.ico-ind-2 {
  background-image: url('../img/ico-ind-2.svg');
}
.ico-ind-3 {
  background-image: url('../img/ico-ind-3.svg');
}
.ico-ind-4 {
  background-image: url('../img/ico-ind-4.svg');
}
.ico-ind-5 {
  background-image: url('../img/ico-ind-5.svg');
}
.ico-ind-6 {
  background-image: url('../img/ico-ind-6.svg');
}
.ico-ind-7 {
  background-image: url('../img/ico-ind-7.svg');
}
.ico-ind-8 {
  background-image: url('../img/ico-ind-8.svg');
}
.ico-ind-9 {
  background-image: url('../img/ico-ind-9.svg');
}
.ico-ind-10 {
  background-image: url('../img/ico-ind-10.svg');
}
.ico-ind-11 {
  background-image: url('../img/ico-ind-11.svg');
}
.ico-ind-12 {
  background-image: url('../img/ico-ind-12.svg');
}
.ico-ind-13 {
  background-image: url('../img/ico-ind-13.svg');
}

@media screen and (min-width: 1024px) {
  .ind__it--2,
  .ind__it--3 {
    border-top: 1px solid #eeeeee;
  }

  .ind__it--4,
  .ind__it--9 {
    border-left: 1px solid #eeeeee;
  }
}

.ind__it-img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    background: linear-gradient(189.93deg, #ffffff 7.45%, rgba(255, 255, 255, 0) 102.1%);
    top: 0;
    right: 0;
    transform: rotateX(180deg);
    bottom: 0;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.ind__it {
  position: relative;

  &:hover {
    .ind__it-img {
      opacity: 1;
      visibility: visible;
    }
  }
}

.ind__it--header {
  width: 40%;
  padding: 4rem 0;
  align-items: center;
}

.sc__ind {
  .sc__title {
    color: #46494a;
    margin-bottom: 2.4rem;
  }
}

.ind__decor {
  width: 20rem;
  height: 0.4rem;
  background: #00ffff;
}

.ind__it--bg {
  position: relative;
}

.ind__it-title {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  position: relative;
  z-index: 2;
  font-family: 'Fedra Sans Alt Pro';
}

.ch__ct-it {
  .sc__txt {
    max-width: 48rem;
  }
}

/*.ch__ct-it--2 {
  .sc__txt{
    max-width: 32.8rem;
  }
}

.ch__ct-it--3 {
  .sc__txt{
    max-width: 32.8rem;
  }
}

.ch__ct-it--4 {
  .sc__txt{
    max-width: 56.7rem;
  }
}

.ch__ct-it--5,
.ch__ct-it--6,
.ch__ct-it--7,
.ch__ct-it--8,
.ch__ct-it--9 {
  .sc__txt{
    max-width: 42rem;
  }
}*/

.sc__model {
  .sc__title {
    color: #46494a;
    text-align: left;
  }

  .sc__header {
    margin-bottom: 4rem;
  }
}

.model__it {
  padding-left: 2rem;
  padding-right: 2rem;
  width: 33.333%;

  @media screen and (max-width: 1023px) {
    width: 100%;
  }
}

.model__it-title {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-family: 'Fedra Sans Alt Pro';
  margin-bottom: 1.4rem;
  color: #46494a;
}

.model__it-descr {
  font-weight: 350;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #46494a;
}

.model__it-bl {
  padding: 4rem;
}

.model__it--dark {
  .model__it-bl {
    background: #eeeeee;
  }
}

.model__list {
  .row {
    margin-right: -2rem;
    margin-left: -2rem;
  }
}

.team__top-left {
  width: 65rem;
  min-width: 65rem;
  margin-right: 15.3rem;
}

.team__top-descr {
  font-size: 1.4rem;
  font-family: 'Fedra Sans Pro';
  line-height: 2.4rem;
}

.team__top-title {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 4.8rem;
  line-height: 6rem;
  font-family: 'Fedra Sans Alt Pro';

  @media screen and (max-width: 1023px) {
    font-size: 2.4rem;
    margin-bottom: 1.4rem;
    line-height: 3.6rem;
    font-weight: 500;
  }
}

.team__line {
  position: absolute;
  width: 13.3rem;
  height: 6.5rem;
  border-top: 1px solid #ff1eff;
  border-right: 1px solid #ff1eff;
  left: -11.3rem;
  top: 0;
}

.team__top-right {
  width: 100%;
  font-weight: 350;
  margin-top: 14.4rem;
  padding-top: 9.1rem;
  position: relative;

  .sc__promo-decor {
    width: 4rem;
    height: 4rem;
    margin-bottom: 2.4rem;
    &:after {
      content: '';
      top: 0.8rem;
      right: 0.8rem;
      width: 1.2rem;
      height: 1.2rem;
      border-right: 0.2rem solid #fff;
      border-top: 0.2rem solid #fff;
      position: absolute;
    }
  }

  @media screen and (max-width: 1023px) {
    font-size: 1.4rem;
    line-height: 2.4rem;
    padding: 0;
    margin-top: 1.4rem;
  }
}

.sc__team {
  .team__it-ct {
    cursor: pointer;
  }
}

.modal__custom-team {
  .modal__box {
    width: 116.8rem;
    padding: 6rem;

    @media screen and (max-width: 1023px) {
      padding: 0;
    }
  }

  .modal__close {
    right: 6rem;
    top: 6rem;

    @media screen and (max-width: 1023px) {
      right: 1rem;
      top: 1rem;
    }
  }
}

.modal__box-left {
  width: 46rem;
  position: relative;
  min-width: 46rem;
  margin-right: 4rem;

  @media screen and (max-width: 1023px) {
    overflow: hidden;
    margin-right: 0;
    width: 100%;
    min-width: 100%;
    height: 32rem;
  }

  img {
    display: block;
    width: 100%;
  }
}

.team__soc {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;

  svg {
    display: block;
    width: 100%;
  }
}

.modal__team-header {
  margin-bottom: 4rem;
  color: #46494a;
}

.modal__team-descr {
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-family: 'Fedra Sans Alt Pro';
}

.modal__team-title {
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.8rem;
  font-family: 'Fedra Sans Alt Pro';
  margin-bottom: 0.8rem;
}

.modal__box-scroll {
  height: 54rem;

  @media screen and (max-width: 1023px) {
    height: auto;
  }
}

.modal__box-subtitle {
  margin-bottom: 1.4rem;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-family: 'Fedra Sans Alt Pro';
  padding-right: 7rem;

  @media screen and (max-width: 1023px) {
    padding-right: 0;
  }
}

.modal__box-right {
  width: 100%;
  text-align: left;

  @media screen and (max-width: 1023px) {
    padding: 2.4rem;
  }
}

.modal__box-txt {
  font-weight: 350;
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-family: 'Fedra Sans Alt Pro';
  padding-right: 7rem;

  @media screen and (max-width: 1023px) {
    padding-right: 0;
  }

  p {
    margin-bottom: 1.4rem;
  }

  & > *:last-child {
    margin-bottom: 0 !important;
  }
}

.post__main {
  background: #eeeeee;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-family: 'Fedra Sans Alt Pro';
  padding: 4rem;
  margin: 2.4rem 0;
}

.team__decor-list {
  margin-bottom: 2rem;
}

.team__decor-1 {
  margin-right: 1.6rem;
  width: 14.2rem;
  height: 0.5rem;
  background: #626262;
}

.team__decor-3 {
  margin-left: 1.6rem;
  width: 14.2rem;
  height: 0.5rem;
  background: #626262;
}

.team__decor-2 {
  width: 16.4rem;
  height: 3.6rem;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.news__it--full {
  height: 100%;

  .news__it-social {
    left: 3rem;
    bottom: 3rem;
  }
}

.news__it--rt-bt {
  .news__it-social {
    left: 3rem;
    bottom: 3rem;
  }
}

.sc__about {
  .sc__title {
    text-align: left;
    color: #46494a;
  }
}

a,
input,
button,
textarea,
.btn__custom,
.sl__pager-it,
.ch__box-it-sl-handle,
.ch__box-it:before,
.ch__box-it-img-box,
.post__action-icon,
.post__action-icon .path,
.sl__nav:before,
.sl__nav:after,
.checkbox__decor:after,
.schema__it-mb-pin:after,
.schema__it-mb-pin:before,
.modal__close,
.modal__box,
.modal__custom,
.ind__it-img {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.post__hd,
.header,
.hd__main,
.hd__logo {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.ch__box-it-sl {
  -webkit-transition: margin-left 0.3s ease;
  -moz-transition: margin-left 0.3s ease;
  -ms-transition: margin-left 0.3s ease;
  -o-transition: margin-left 0.3s ease;
  transition: margin-left 0.3s ease;
}

@media screen and (min-aspect-ratio: 8/5) and (min-width: 1024px) {
  html {
    font-size: 1.031vh;
  }
}

@media screen and (max-aspect-ratio: 8/5) and (min-width: 1024px) {
  html {
    font-size: 0.66667vw;
  }
}

@media screen and (max-width: 1023px) {
  .post__details-mb {
    height: 4.4rem;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 1.6rem;
  }

  .post__date {
    font-size: 1rem;
    line-height: 1.2rem;
    color: #626262;
    font-family: 'Fedra Sans Pro';
  }

  .post__text-box {
    .row__custom {
      margin-left: -0.8rem;
      margin-right: -0.8rem;
    }

    .col {
      padding-right: 0.8rem;
      padding-left: 0.8rem;
    }
  }

  .subscribe__form {
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
  }

  .hd__main {
    height: 7rem;
  }

  .header--small {
    transform: translateY(-8.8rem);
  }

  .section {
    padding-top: 7rem;
  }

  .sc__promo {
    padding-top: 15.8rem;
  }

  .sc__promo-bottom {
    margin-bottom: 0;
  }

  .ab__box {
    margin-top: 0;
  }

  .section__scroll-box {
    left: 3.2rem;
    margin-left: 0;
    top: 6rem;
  }

  .sc__news {
    .container {
      padding-top: 4rem;
    }
  }

  .news__sl-box {
    margin-bottom: 0;
  }

  #pr-span-decor-2 {
    left: auto;
    right: 0;
  }

  .sc__promo-subtitle {
    width: 100%;
  }

  .sc__miss-wrap {
    .container {
      height: 100%;
      padding-top: 7rem;
    }
  }

  .sc__mission {
    padding-top: 6.2rem;
    padding-bottom: 4rem;
    height: 100%;
    align-content: space-between;
  }

  .schema__target {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 3.2rem;
  }

  .sc__target {
    padding-bottom: 3.2rem;
  }

  .schema__active {
    &:not(.active) {
      display: none;
    }
  }

  .schema__it-mb {
    &.active {
      .schema__it-mb-title {
        display: none;
      }

      .schema__it-mb-pin {
        &:before {
          transform: scale(0);
          opacity: 0;
        }
        &:after {
          transform: scale(1);
          opacity: 1;
        }
      }
    }
  }

  .schema__active--1 {
    position: absolute;
    width: 6.6rem;
    left: 2.3rem;
    bottom: 4.55rem;
    img {
      display: block;
      width: 100%;
    }
  }

  .schema__active--2 {
    position: absolute;
    width: 8.4rem;
    left: 13.9rem;
    bottom: 9.9rem;
    img {
      display: block;
      width: 100%;
    }
  }

  .schema__active--3 {
    position: absolute;
    width: 7.7rem;
    right: 1.94rem;
    bottom: 16.6rem;
    img {
      display: block;
      width: 100%;
    }
  }

  .schema__box-top {
    &:not(.active) {
      display: none !important;
    }
  }

  .schema__box {
    margin-bottom: 0;
    width: 100%;
  }

  .sc__activities {
    & > div {
      &:nth-child(2) {
        height: 100%;
      }
    }

    .container {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  .sc__challenges {
    .section__scroll-box {
      right: auto;
      margin-right: 0;
      left: 3.2rem;
      margin-left: 0;
      top: 6rem;
    }

    .scroll__down {
      flex-direction: row !important;
    }

    .scroll__down-arrow {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .ch__mobile-sl-img {
    position: relative;
    margin-bottom: 13.4rem;

    img {
      display: block;
      width: 100%;
      height: 31rem;
      object-fit: cover;
    }
  }

  .ch__mobile-sl-numb {
    position: absolute;
    height: 10rem;
    top: 1.5rem;
    left: 0.9rem;
    img {
      display: block;
      height: 100%;
    }
  }

  .ch__mobile-decor-box {
    position: absolute;
    left: 0;
    top: 33rem;
    right: 0;
    height: 10rem;

    .slick-list {
      overflow: visible;
    }

    .slick-slide {
      &:nth-child(9n + 1) {
        .ch__box-it-sl {
          animation-delay: 0.15s;
          height: 5.4rem !important;
        }
      }

      &:nth-child(9n + 2) {
        .ch__box-it-sl {
          animation-delay: 0.2s;
          height: 5.8rem !important;
        }
      }

      &:nth-child(9n + 3) {
        .ch__box-it-sl {
          animation-delay: 0.25s;
          height: 4.8rem !important;
        }
      }

      &:nth-child(9n + 4) {
        .ch__box-it-sl {
          animation-delay: 0.3s;
          height: 6.2rem !important;
        }
      }

      &:nth-child(9n + 5) {
        .ch__box-it-sl {
          animation-delay: 0.35s;
          height: 3.2rem !important;
        }
      }

      &:nth-child(9n + 6) {
        .ch__box-it-sl {
          animation-delay: 0.4s;
          height: 5.2rem !important;
        }
      }

      &:nth-child(9n + 7) {
        .ch__box-it-sl {
          animation-delay: 0.45s;
          height: 4.4rem !important;
        }
      }

      &:nth-child(9n + 8) {
        .ch__box-it-sl {
          animation-delay: 0.5s;
          height: 5.6rem !important;
        }
      }

      &.slick-current {
        .ch__box-it-sl-handle {
          background-color: #ff1eff;
          width: 1.2rem;
        }

        .ch__box-it-sl {
          margin-left: -0.6rem;
          height: 6.4rem !important;
        }
      }
    }
  }

  .ch__mobile-decor {
    width: 4.8rem;
    height: 10rem;
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }

  .ch__mobile-box {
    position: relative;
  }

  .ch__box-it {
    height: 100%;
  }

  .ch__box-it-sl {
    left: 0;
    height: 6.4rem !important;
  }

  .hist__col--2 {
    margin-bottom: 1.6rem;
  }

  .sc__history {
    padding-top: 1.6rem;
  }

  .sc__quote,
  .sc__principles,
  .sc__ind,
  .sc__challenges,
  .sc__model,
  .sc__team-top,
  .sc__about,
  .sc__history{
    height: auto !important;
    padding-top: 14.4rem !important;
    padding-bottom: 1.6rem !important;

    .section__scroll-box {
      top: 3.2rem;
      right: auto;
      margin-right: auto;
      margin-left: 0;
    }
  }

  .sc__quote {
    padding-top: 12.4rem !important;
  }

  .prin__box-ct {
    margin-bottom: 2.4rem;
  }

  .scroll__down-title {
    text-align: left !important;
  }

  .team__top-box {
    flex-wrap: wrap;

    .sc__promo-decor {
      width: 4rem;
      height: 4rem;

      &:after {
        top: 0.6rem;
        right: 0.6rem;
        width: 1.2rem;
        height: 1.2rem;
        border-right: 0.2rem solid #fff;
        border-top: 0.2rem solid #fff;
      }
    }
  }

  .team__top-left {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    padding: 0;
  }

  .sc__activities,
  .sc__news,
  .sc__contacts {
    height: auto !important;
    padding-top: 14.4rem !important;
    padding-bottom: 1.6rem !important;
  }

  .sc__team {
    height: auto !important;
    padding-top: 4rem !important;
    padding-bottom: 1.6rem !important;

    .team__it-ct {
      position: relative;
      padding: 1.6rem 0;
    }

    .team__it-ct-bl {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }

    .team__it-position {
      font-size: 1.1rem;
      line-height: 1.6rem;
    }

    .team__it-name {
      margin-bottom: 0.8rem;
    }

    .team__it-arr {
      margin-top: 0.8rem;
      margin-left: auto;
      margin-right: 0;
    }
  }

  .team__it {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
  }

  .team__box-row {
    margin-right: -0.2rem;
    margin-left: -0.2rem;
  }

  .team__box {
    margin-top: 0;
  }

  .map {
    margin: 4rem 0 1.6rem;
    height: 36rem;
  }

  .modal__box-ct {
    flex-wrap: wrap;
    height: 100vh;
    overflow-y: auto;
  }

  .modal__box {
    height: 100vh;
    overflow-y: auto;
  }

  .sc__target {
    padding-top: 3rem !important;
  }

  #pr-span-decor-4 {
    height: 0.4rem;
    margin-top: 0.9rem;
    left: 28.7rem;
    width: 7.4rem;
  }

  #pr-span-decor-3 {
    margin-top: 5.8rem;
    left: 9.5rem;
  }

  .subtitle-line {
    height: 0.3rem;
    margin-top: 0.9rem;
    left: 28.7rem;
    width: 7.4rem;
  }

  #pr-span-decor-2 {
    left: 0;
    height: 0.4rem;
    width: 7.7rem;
    margin-top: 6.9rem;
  }

  .activities__it-descr {
    max-width: 28rem;
  }

  .activities__list {
    padding-left: 3.6rem;
  }

  .quote__box {
    .team__it-bl img {
      height: 42rem;
      object-fit: cover;
    }

    .team__it-position {
      line-height: 1.7rem;
    }
  }

  .sc__team-top {
    padding-bottom: 0 !important;
  }

  .news__it-slide--1 {
    .news__it-slide-ct {
      height: 53.6rem;
    }
  }

  .ab__it {
    padding-left: 0;
    padding-right: 0;
  }

  .ab__it-title {
    margin-bottom: 1.6rem;
  }

  .ab__it-line {
    background-color: #626262;
  }

  /*.ab__it {
    &:not(:last-child){
      margin-bottom: 4rem;
    }
  }*/

  .ab__it {
    height: 100%;
    padding: 0;
    position: absolute;
    left: 0;
    top: 0;

    &:not(.active) {
      display: none !important;
    }
  }


  .sc__about {
    margin-bottom: 0;
  }


  .ind__it-title {
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 2.2rem;
  }

  .ind__it:last-child {
    width: 100%;
    padding: 5rem 4rem;
    align-items: center;
    display: flex;
    flex-direction: row;
    .ind__it-title {
      text-align: left;
      margin-left: 4rem;
    }
   }

  .ind__it {
    padding-bottom: 0;
  }

  #pr-span-4 {
    display: block;
  }
}
